import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import Welcome from '../components/Welcome';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMe } from '../features/authSlice';

import '../iframe.css';
import '../dashboard.css';


const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isError} = useSelector((state=>state.auth));
  const {user} = useSelector((state)=>state.auth)

  useEffect(()=>{
      dispatch(getMe());
  },[dispatch]);

  useEffect(()=>{
      if(isError){
          navigate("/");
      }
  },[isError,navigate]);

  const updateIframeHeight = () => {
    const screenHeight = window.innerHeight || document.documentElement.clientHeight;
    const iframe = document.getElementById("yourIframeId"); // Make sure to set the id on your iframe

    if (iframe) {
      iframe.style.height = `${screenHeight}px`;
    }
  };

  useEffect(() => {
    // Add an event listener to update the iframe height on window resize
    window.addEventListener("resize", updateIframeHeight);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateIframeHeight);
    };
  }, []); // Run this effect only once when the component mounts


  return (
    <Layout>
      {user && (
    <iframe
      id="yourIframeId"
      onLoad={updateIframeHeight}
      src={`https://deiktes.streamlit.app/?role=${user.role}&embedded=true`}
      style={{ width: "100%", border: "none" }}
    ></iframe>
    )}
    </Layout>
  );
};



//   return (
//     <Layout>
//       <Welcome />
     
//       {user && (
//       <iframe src={`https://deiktes.streamlit.app/?role=${user.role}&embedded=true`} height="fix-content"  style={{ width: "100%", border: "none" }}></iframe>

//             )}

//       {/* <div className="dashboard-container">
 
//           </div> */}

// {/* {user && (
//         <h1>User Role: {user.role}</h1>
//       )} */}
//     </Layout>
//   );
// };

export default Dashboard;
