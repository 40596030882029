import React from "react";
import {NavLink} from "react-router-dom";
import logo from "../logo2.png";
import { useDispatch,useSelector } from 'react-redux'
import {IoPerson,IoPricetag,IoHome,IoLogOut} from "react-icons/io5";
import { FaUsersGear } from "react-icons/fa6";

import { useNavigate } from 'react-router-dom'
import {LogOut, LoginUser,reset} from "../features/authSlice"

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import { FaUser } from "react-icons/fa";


import '../navbar-custom.css';
const Navbar =()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {user} = useSelector((state)=>state.auth)
    const logout = ()=>{
        dispatch(LogOut());
        dispatch(reset());
        navigate("/");
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    // const handleChange = (event) => {
    //   setAuth(event.target.checked);
    // };
  
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  


    return(
        <div>
            {/* <nav className="navbar is-fixed-top has-shadow" style={{"zIndex": "2000"}} role="navigation" aria-label="main navigation">
              <div className="navbar-brand">
                <NavLink className="navbar-item" to="/dashboard">
                    <img 
                        src={logo}
                        width="220" 
                        height="80"
                        alt="logo"
                    />
                </NavLink>
            
                <a href='!#' role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
              </div>
            
              <div id="navbarBasicExample" className="navbar-menu">
                
            
                <div className="navbar-end">

                <div className="navbar-item">
                    <h2 className='subtitle'>Καλώς ορίσατε <strong>{user && user.name}</strong></h2>

                    </div>
                  <div className="navbar-item">
                  {user && user.role ==="admin" && (
                    <NavLink to="/users" ><IoPerson/> Χρήστες</NavLink>
            // <SubMenu label="Διαχειριστής" defaultOpen={isSubMenuOpen}>
                
                // <MenuItem onChange={() =>setIsSubMenuOpen(true)}></MenuItem>

            // </SubMenu>
            )}
                  </div>

                  <div className="navbar-item">
                   
                    <div className="buttons">
                      
                      <button onClick={logout} className="button is-light">
                        Αποσύνδεση
                      </button>
                    </div>
                  </div>

                  <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              ><MenuItem onClick={handleClose}>Profil2e</MenuItem>
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Mtest</MenuItem>
              </Menu>
            </div>



                </div>
              </div>
            </nav> */}
<nav className="navbar is-fixed-top has-shadow"  role="navigation" aria-label="main navigation">
<Box sx={{ flexGrow: 1 }}>
     

        <Toolbar>
       
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            
          <NavLink className="navbar-item" to="/dashboard">
                    <img 
                        src={logo}
                        width="220" 
                        height="80"
                        alt="logo"
                    />
                </NavLink>
          </Typography>
         
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >

                <MenuItem><FaUser/><span style={{paddingLeft:"10px"}}>{user && user.name} ({user && user.role})</span></MenuItem>
                {user && user.role ==="admin" && ( 
                <MenuItem  component={Link} to="/users" ><FaUsersGear/><span style={{paddingLeft:"10px"}}> Διαχείρηση Χρηστών</span></MenuItem>
                )}

               <MenuItem onClick={logout}><IoLogOut/><span style={{paddingLeft:"10px"}}> Αποσύνδεση</span></MenuItem>

              
              </Menu>
            </div>
      
        </Toolbar>
    </Box>
    </nav>



        </div>
    )
}
export default Navbar